import React, { useEffect } from 'react';
import {
  subscribeToPush,
  registerServiceWorker,
  unsubscribeFromPush,
  notifyMe,
} from './push-notification';

function NotificationComponent() {
  useEffect(() => {
    // Ensure Notification API is available
    if ('Notification' in window) {
      if (Notification.permission === 'default') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            subscribeToPush();
          }
        });
      } else if (Notification.permission === 'granted') {
        subscribeToPush();
      }
    } else {
      console.error('This browser does not support notifications.');
    }

    // Call updateUI after component mounts (if you need this functionality)
    // updateUI();
  }, []);

  function notifyMee() {
    if (Notification.permission === 'granted') {
      // Customize the notification
      const options = {
        body: 'This is the body text of the notification.',
        icon: 'path_to_icon.png', // URL to an icon image
        image: 'path_to_image.jpg', // URL to an image (for notifications that support it)
        badge: 'path_to_badge.png', // Smaller icon to display on mobile devices
        vibrate: [200, 100, 200], // Vibration pattern for mobile devices
        actions: [
          { action: 'explore', title: 'Go to website' },
          { action: 'close', title: 'Dismiss' },
        ],
        tag: 'example-notification', // Tag to prevent multiple notifications stacking
        renotify: false, // If true, the user is notified even if there's already a notification with the same tag
        data: { url: 'https://yourwebsite.com' }, // Custom data to handle when notification is clicked
      };

      // Show the notification
      const notification = new Notification('Custom Notification Title', options);

      // Handle notification click event
      notification.onclick = (event) => {
        event.preventDefault(); // Prevent default action (e.g., opening a webpage)
        window.open(notification.data.url, '_blank'); // Open a URL when the notification is clicked
      };
    } else if (Notification.permission === 'default' || Notification.permission === 'denied') {
      Notification.requestPermission();
    }
  }

  return (
    <>
      {/* <div> */}
      {/* Uncomment the buttons if you want to provide manual controls */}
      {/* <button id="register" onClick={registerServiceWorker}>
          Register Service Worker
        </button>
        <button id="unregister" onClick={unsubscribeFromPush}>
          Unsubscribe from Push
        </button>
        <button id="subscribe" onClick={subscribeToPush}>
          Subscribe to Push
        </button> */}
      {/* <button id="notify-me" onClick={notifyMee}>
          Notify Me
        </button> */}
      {/* </div> */}
    </>
  );
}

export default NotificationComponent;
