import { useRef } from 'react';

export const useLongPress = ({ cb, time = 1000, onTrigger }) => {
  const timeoutIdRef = useRef(null);

  const startPressTimer = (event) => {
    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    const position = { x: clientX, y: clientY };
    timeoutIdRef.current = setTimeout(() => {
      onTrigger(true);
      cb({ event, position });
    }, time);
  };

  const clearPressTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
      onTrigger(false);
    }
  };

  const onTouchStart = (e) => {
    startPressTimer(e);
  };

  const onTouchMove = () => {
    clearPressTimer();
  };

  const onTouchEnd = (event) => {
    clearPressTimer();
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
