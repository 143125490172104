import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgress, MenuItem } from '@material-ui/core';
import { useLongPress } from './hook/useLongPress';
import Network from '../Requests';
import CreateProject from '../CreateProject';
import { MenuCard } from './components/MenuCard';
import defaultImg from '../../images/def_chat.png';
import { isMobile } from 'react-device-detect';

const RenderCardsMemo = ({
  products,
  currentInterior,
  InteriorClicked,
  groupColor,
  onResetChat,
  currentGroup,
}) => {
  const [state, setState] = useState({
    items: [],
    isPreloader: false,
    error: '',
    menuCardData: [],
    requestId: '',
    isMobile: false,
    currentChat: '',
    phoneProject: '',
  });

  const [scrolling, setScrolling] = useState(false);
  const [isLongPressTriggered, setIsLongPressTriggered] = useState(false);
  const [menuReady, setMenuReady] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const setStateHelper = (payload) => setState((prev) => ({ ...prev, ...payload }));
  const menuRef = useRef(null);
  const positionRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (isMobile) {
      setStateHelper({ isMobile: true });
    }
  }, []);

  useEffect(() => {
    if (menuReady && menuRef.current) {
      const { x, y } = positionRef.current;
      menuRef.current.show(x, y);
    }
  }, [menuReady]);

  const requestInfoForMenu = useCallback(
    async ({ event, position }) => {
      if (!event || !position) {
        console.error('Event or position is missing');
        return;
      }

      if (scrolling) return;

      try {
        const target = event.target.closest('[data-requestid]');
        const requestId = target.dataset?.requestid || '';
        console.log(requestId, 'requestIdrequestId');

        setStateHelper({ requestId, menuCardData: [{ name: 'Загрузка данных...' }] });
        setMenuReady(false);

        const storage = localStorage.getItem('company');
        const storageCompany = storage ? JSON.parse(storage) : null;
        if (!storageCompany || !storageCompany.id) {
          alert('Ошибка: данные компании отсутствуют или повреждены');
          setStateHelper({ menuCardData: [{ name: 'Ошибка загрузки данных' }] });
          return;
        }

        const response = await new Network().GetGroups(storageCompany.id, requestId);
        const folders = Array.isArray(response) ? response : [{ name: 'Нет групп для переноса' }];

        const phone = target?.dataset?.phonenumber || '';
        if (phone) {
          folders.push({ name: 'Скопировать номер', type: 'copy', phone });
        }

        setStateHelper({ menuCardData: folders, currentChat: requestId });
        positionRef.current = position; // Сохраняем позицию для использования в useEffect
        setMenuReady(true);
      } catch (error) {
        alert('Ошибка при запросе данных для меню:', error);
        setStateHelper({ menuCardData: [{ name: 'Ошибка загрузки данных' }] });
        setMenuReady(false);
      }
    },
    [scrolling],
  );

  const dataEvents = useLongPress({
    cb: requestInfoForMenu,
    time: 1000,
    onTrigger: setIsLongPressTriggered,
  });

  const handleClickField = (item) => {
    if (item?.guid) {
      const fetch = async () => {
        setIsMoving(true); // Начинаем загрузку
        try {
          await new Network().MoveProjectInGroup(state?.currentChat, item?.guid);
          onResetChat();
        } catch (error) {
          console.error('Ошибка перемещения проекта:', error);
        } finally {
          setIsMoving(false); // Завершаем загрузку
        }
      };
      fetch();
    }
    if (menuRef.current) {
      menuRef.current.hide();
    }
  };

  const handleCopy = (phone) => {
    navigator.clipboard.writeText(phone).catch((error) => {
      console.error('Failed to copy text:', error);
    });
    if (menuRef.current) {
      menuRef.current.hide();
    }
  };

  const handleClickRightButton = (event) => {
    event.preventDefault();
    const { clientX, clientY } = event;
    requestInfoForMenu({ event, position: { x: clientX, y: clientY } });
  };

  const handleTouchStart = (event) => {
    if (event.touches && event.touches.length > 0) {
      setScrolling(false);
      setIsLongPressTriggered(false);
      dataEvents.onTouchStart(event);
    }
  };

  const handleTouchMove = () => {
    setScrolling(true);
    dataEvents.onTouchMove();
  };

  const handleTouchEnd = (event) => {
    if (!scrolling && isLongPressTriggered) {
      dataEvents.onTouchEnd(event);
    }
    setScrolling(false);
  };

  return (
    <>
      {products.map((c) => (
        <div
          key={`${c.projectGuid}-${currentGroup}-${c.id}-${c.timestamp}`}
          className="project-wth-dell"
          style={{ position: 'relative' }}
          onContextMenu={handleClickRightButton}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}>
          <div
            id={c.projectGuid}
            data-requestid={c.projectGuid}
            data-phonenumber={c.phoneNumber}
            className={`flex pr-item ${
              `${c.projectGuid}-${c.guidDisplayTape ?? ''}` ===
              `${currentInterior?.projectGuid}-${currentInterior?.guidDisplayTape ?? ''}`
                ? 'active'
                : ''
            }`}
            onClick={() => InteriorClicked(c)}>
            <div
              style={{
                backgroundColor: c.currentCRMGroup ? c.currentCRMGroup.color : groupColor,
              }}
              className="project-color"
            />
            <div
              className="pr-photo"
              style={{
                backgroundImage: `url("${c.projectSmallPicturePath || defaultImg}")`,
              }}>
              {c?.isAuthor && <div className="author">A</div>}
            </div>
            <div className="pr-info">
              <div className="pr-title">
                {c.name.length > 24 ? c.name.substr(0, 24) + '...' : c.name}
              </div>
              <div className="pr-descr">
                {c.lastMessage?.content
                  ? c.lastMessage.content.length > 24
                    ? c.lastMessage.content.substr(0, 24) + '...'
                    : c.lastMessage.content
                  : ''}
              </div>
              {c.unreadMessagesCount > 0 && (
                <div className="pr-container">
                  <div className="pr-counter">
                    <div className="pr-counter--item view">{c.unreadMessagesCount}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {c.forDisplayTape && <CreateProject guid={c.guidDisplayTape} />}
          {state.requestId === c.projectGuid && isMoving && (
            <div style={{ position: 'absolute', top: 12, right: 12 }}>
              <CircularProgress size={20} />
            </div>
          )}
        </div>
      ))}

      <MenuCard ref={menuRef}>
        {state.menuCardData?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() =>
              item.type === 'copy' ? handleCopy(item.phone) : handleClickField(item)
            }>
            {item.name}
          </MenuItem>
        ))}
      </MenuCard>
    </>
  );
};

export const RenderCards = React.memo(RenderCardsMemo);
